export class BadDataError extends Error {
  constructor(message, culprits) {
    super(message);
    this.message = message;
    this.culprits = culprits;
  }
}

export class ValidationError extends Error {
  constructor(message, results) {
    super(message);
    this.message = message;
    this.results = results;
  }
}

export class AnalyticsError extends Error {
  constructor(message, originalError) {
    super(message);
    this.message = message;
    this.originalError = originalError;
  }
}

export class QuoteError extends Error {
  constructor(message, inputs, originalError) {
    super(message);
    this.message = message;
    this.inputs = inputs;
    this.originalError = originalError;
  }
}

export class AgentError extends Error {
  constructor(message, inputs, originalError) {
    super(message);
    this.message = message;
    this.inputs = inputs;
    this.originalError = originalError;
  }
}
