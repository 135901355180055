const locales = Object.freeze({
  EN_US: "en-US",
});

const currency = Object.freeze({
  USD: "USD",
});

export function formatNumber(number) {
  return new Intl.NumberFormat(locales.EN_US).format(number);
}

export function formatCurrency(number) {
  return new Intl.NumberFormat(locales.EN_US, { style: "currency", currency: currency.USD }).format(number);
}

export function addCommas(value) {
  if (value) return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return "";
}
