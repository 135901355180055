// @TODO Move to utils/queries.js seems to be a better option as this isn't a store
import { gql } from "@apollo/client/core";

export const quoteQuery = gql`
  query quote($interviewID: String!) {
    quote(interviewID: $interviewID) {
      success
      reason
      quote {
        isEstimate
      }
    }
  }
`;

export const interviewStatusQuery = gql`
  query interview($id: String!) {
    interview(id: $id) {
      status
    }
  }
`;

export const getInterviewMarkerByNameQuery = gql`
  query getInterviewMarkerByName($id: String!, $name: String!) {
    getInterviewMarkerByName(id: $id, name: $name) {
      metadata {
        key
        value
      }
      name
    }
  }
`;

export const accountQuery = gql`
  query account($token: String!) {
    account(token: $token) {
      id
      userRole
      agentId
      interviews {
        agentId
        applicant {
          firstName
          lastName
          emailAddress
          phoneNumbers {
            number
            type
          }
        }
        id
        firstName
        product {
          variant
          vendor
          states
        }
        questions(where: { key: "ACCEPTED_NOTICES" }) {
          key
          answers
        }
        status
        policy {
          id
          number
          status
          startDate
          endDate
          semiMonthlyBenefit
          coveredIncome
          monthlyPremium
          termPeriod
          flags
          holder {
            firstName
            lastName
            emailAddress
            accountID
            dateOfBirth
            gender
            address {
              line1
              line2
              city
              state
              postalCode
              countryCode
            }
          }
          beneficiaries {
            relationship
            trust {
              name
              formationDate
            }
            person {
              firstName
              lastName
              emailAddress
              accountID
              dateOfBirth
              gender
              address {
                line1
                line2
                city
                state
                postalCode
                countryCode
              }
            }
          }
        }
      }
    }
  }
`;

export const getAgent = gql`
  query getAgent($id: String!) {
    agent(id: $id) {
      id
      name
      imageUrl
      agentEmail
      phone
    }
  }
`;
