<template>
  <component
    :is="as"
    :class="[
      $style.button,
      $style[variant],
      $style[size],
      { [$style.icon]: icon },
      { [$style.outline]: outline },
      { [$style.pill]: pill },
      { [$style.plain]: plain },
      { [$style.stretch]: stretch },
      { [$style.loading]: loading },
    ]"
    :type="type"
    :role="role"
    :disabled="disabled"
    v-bind="$attrs"
  >
    <slot>Button</slot>
  </component>
</template>

<script setup>
import { computed, useAttrs } from "vue";

const props = defineProps({
  type: {
    type: String,
  },
  size: {
    type: String,
    validator: (prop) => ["xs", "sm", "lg", "xlg"].includes(prop),
  },
  variant: {
    type: String,
    validator: (prop) => ["danger", "icon", "primary", "reverse", "secondary", "transparent", "warning"].includes(prop),
  },
  disabled: {
    type: Boolean,
    default: null,
  },
  pill: Boolean,
  outline: Boolean,
  icon: Boolean,
  plain: Boolean,
  stretch: Boolean,
  loading: Boolean,
});

const attr = useAttrs();

const as = computed(() => (!attr.to && !attr.href ? "button" : attr.to ? "router-link" : "a"));
const type = computed(() => (props.type ? props.type : !props.to && !attr.href ? "button" : null));
const role = computed(() => (!attr.to && as.value !== "button" && !attr.href ? "button" : null));
</script>

<style lang="scss" module>
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 60px;
  padding: 0 20px;
  color: currentColor;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  text-decoration: none;
  background: var(--base-button-background);
  border: 1px solid transparent;
  border-radius: 6px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  outline: none;
  cursor: pointer;
  transition: background-color 230ms ease-in-out, box-shadow 230ms ease-in-out;
  &:focus-visible {
    box-shadow: 0 0 0 4px rgba(26, 17, 60, 0.2); // rgba(208, 44, 45, 0.3);
  }
  &:disabled {
    background: var(--neutral) !important;
    color: var(--midnight) !important;
    border-color: var(--neutral) !important;
    opacity: 0.4;
    cursor: not-allowed;
  }
}

// Fallback for safari not support :focus-visible support
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .button {
      &:focus {
        box-shadow: 0 0 0 4px rgba(26, 17, 60, 0.2); // rgba(208, 44, 45, 0.3);
      }
    }
  }
}

a.button:not(.outline) {
  &:hover,
  &:focus-visible {
    border-bottom-color: transparent;
  }
}

@media (min-width: 600px) {
  .button {
    min-width: 164px;
  }
}

@media (min-width: 756px) {
  .button {
    width: max-content;
  }
  .lg {
    min-width: 224px;
  }
}

.primary,
.secondary {
  padding: 0 30px;
  color: var(--oat);
  &.loading {
    &:after {
      border-top-color: #fff;
    }
  }
}
.primary {
  background: var(--base-color-primary, #e33031);
  border-color: var(--base-color-primary, #e33031);
  &:hover,
  &:focus-visible {
    background: var(--base-color-primary-hover, #d02c2d);
    border-color: var(--base-color-primary-hover, #d02c2d);
  }
  &.outline {
    border-color: rgba(227, 48, 49, 0.4); // Should remove this
    &:focus-visible {
      background: rgba(227, 48, 49, 0.15);
    }
    &.loading {
      &:after {
        border-top-color: var(--base-color-primary, #e33031);
      }
    }
  }
}

// Fallback for safari not support :focus-visible support
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .primary {
      &:focus {
        background: var(--base-color-primary-hover, #d02c2d);
        border-color: var(--base-color-primary-hover, #d02c2d);
      }
      &.outline {
        &:focus {
          background: rgba(227, 48, 49, 0.15);
        }
        &.loading {
          &:after {
            border-top-color: var(--base-color-primary, #e33031);
          }
        }
      }
    }
  }
}

.secondary {
  background: var(--base-color-secondary, #1a113c);
  &.outline {
    border-color: var(--base-color-secondary, #1a113c);
    color: var(--base-color-secondary, #1a113c);
    opacity: 0.7;
    &:hover,
    &:focus-visible {
      background: rgba(26, 17, 60, 0.1);
    }
  }
}

// Fallback for safari not support :focus-visible support
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .secondary {
      &.outline {
        &:focus {
          background: rgba(26, 17, 60, 0.1);
        }
      }
    }
  }
}

.reverse {
  background: var(--oat, #f2ede0);
  color: var(--base-color-secondary, #1a113c);
  &.outline {
    border-color: var(--oat, #f2ede0);
    color: var(--oat, #f2ede0);
    opacity: 0.8;
    &:hover {
      opacity: 1;
      background: rgba(242, 237, 224, 0.15);
    }
  }
}
.icon {
  border-radius: 100%;
  width: 42px;
  height: 42px;
  min-width: 42px;
  min-height: 42px;
  padding: 0;
  &:hover,
  &:focus-visible {
    border-bottom: transparent;
    box-shadow: none;
  }
}

.icon:not(.primary) {
  color: currentColor;
  background: transparent;
  border: 0;
  &:focus-visible,
  &:hover {
    background: var(--base-button-icon-hover, rgba(0, 0, 0, 0.06));
  }
  &.xs {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    padding: 5px;
  }
  &.sm {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    padding: 5px;
  }
}

.xs {
  padding: 0 16px;
  min-height: 36px;
  font-size: var(--base-text-sm, 0.875rem);
}

.sm {
  padding: 0 18px;
  min-height: 48px;
  font-size: var(--base-text-root, 1rem);
}

.pill {
  border-radius: 10vh;
}

.outline {
  background: transparent;
  color: var(--base-color-primary, #e33031);
  &:hover,
  &:focus-visible {
    background: rgba(227, 48, 49, 0.15);
  }
}
.loading {
  position: relative;
  color: transparent;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: var(--base-color-secondary, #1a113c);
    border-radius: 50%;
    animation: spinner 1000ms ease infinite;
    background: transparent !important;
    opacity: 1;
  }
  &.icon svg {
    opacity: 0;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.transparent {
  background: transparent;
  color: var(--base-color-primary, #e33031);
  border: transparent;
  &:hover {
    border-bottom: 0;
  }
}

.plain {
  width: fit-content;
  min-height: auto;
  padding: 0;
  text-transform: none;
}

.stretch {
  width: 100%;
}
</style>
