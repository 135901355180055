import { useInterviewData } from "@composables";
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { routeNames } from "../constants";
import { useInterviewStore } from "../store/interview.store";
import { useUserStore } from "../store/user.store";
import { acceptedNotice, imoAccount } from "../utils";

const from = ref(null);

/**
 * Initial composable to control redirects base on conditions
 * @returns object
 */
export function useRedirect() {
  //const redirected = ref(false);
  const userStore = useUserStore();
  const interviewStore = useInterviewStore();
  const { interview } = useInterviewData();
  const router = useRouter();

  // IMO - legacy, remove?
  function _imoRedirects() {
    const accepted = acceptedNotice(interviewStore.interview);
    // Redirect IMO applicatns that have not accepted notice to imo consent page
    if (!accepted) {
      router.replace({
        name: routeNames.DEMO_APPLICANT_CONSENT,
        query: { interviewId: interviewStore.id },
      });
    }
    // Redirect IMO applicatns that have accepted notice to imo consent confirmed page
    if (accepted) {
      router.replace({
        name: routeNames.DEMO_APPLICANT_REVIEW,
        query: { interviewId: interviewStore.id },
      });
    }
  }

  function _agentLedRedirects() {
    console.log("---", userStore.user);
    const accepted = interview.value.questions.find(({ key }) => key === "ACCEPTED_NOTICES").answers[0];
    // Redirect agent-led applicants that have not accepted notice to demo consent page
    if (!accepted) {
      router.replace({
        name: routeNames.DEMO_APPLICANT_CONSENT,
        query: { interviewId: interview.value.id },
      });
    }
    // Redirect agent-led applicants that have accepted notice to demo review page
    if (accepted) {
      router.replace({
        name: routeNames.DEMO_APPLICANT_REVIEW,
        query: { interviewId: interview.value.id },
      });
    }
  }

  // Global redirect method
  // @TODO add props variable for futre use
  function redirect() {
    // Agent-led redirects for Demo
    if (userStore.hasProp("$user") && interview.value.agentLed) {
      _agentLedRedirects();
      return;
    }

    // IMO - legacy, remove?
    // Redirect user agents to dashboard
    if (userStore.hasProp("$user") && userStore.isAgent) {
      router.replace({ name: routeNames.IMO_DASHBOARD_APPLICATIONS });
      return;
    }
    if (userStore.interview && imoAccount(userStore.interview)) _imoRedirects();
    return;
  }

  return {
    from,
    redirect,
  };
}

export const Redirect = defineComponent({
  name: "Redirect",
  setup() {
    const route = useRoute();
    const redirectBaseURL = process.env.VUE_APP_REDIRECT_BASE_URL || "https://app.dayforward.com";
    window.location = `${redirectBaseURL}${route.path}`;
    return () => {
      return null;
    };
  },
});
