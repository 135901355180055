//import { useMutaion } from "./mutation";
import ADD_TO_WAITINGLIST from "@api/graphql/addToWaitlist.mutation.gql";
import { useReporter } from "@common/composables";
import { readonly, ref } from "vue";

import { ROLES, STATUS } from "../constants";
import QUESTIONS from "../data/questions.json";
import ADD_CREDIT_CARD from "../graphql/addCreditCard.mutation.gql";
import ADD_INTERACTION from "../graphql/addInteraction.mutation.gql";
import ADD_INTERVIEW_META from "../graphql/addInterviewMeta.mutation.gql";
import ADD_MARKER from "../graphql/addMarker.mutation.gql";
import BIND_AGENT from "../graphql/bindAgent.mutation.gql";
import CREATE_ACCOUNT from "../graphql/createAccount.mutation.gql";
import CREATE_APPLICANT from "../graphql/createApplicant.mutation.gql";
import CREATE_BILLING_ACCOUNT from "../graphql/createBillingAccount.mutaion.gql";
import CREATE_INTERVIEW from "../graphql/createInterview.mutation.gql";
import GET_ACCOUNT from "../graphql/getAccount.query.gql";
import ACCOUNT_VERIFICATION from "../graphql/getAccountVerification.query.gql";
import GET_ADDRESS from "../graphql/getAddress.query.gql";
import GET_AGENCY from "../graphql/getAgency.query.gql";
import GET_AGENCY_INTERVIEWS from "../graphql/getAgencyInterviews.query.gql";
import GET_AGENT from "../graphql/getAgent.query.gql";
import GET_AGENT_INTERVIEWS from "../graphql/getAgentInterviews.query.gql";
import GET_ANSWERS from "../graphql/getAnswers.query.gql";
import GET_CREDIT_CARDS from "../graphql/getCreditCards.query.gql";
import GET_DIAGNOSES from "../graphql/getDiagnoses.query.gql";
import GET_DIAGNOSIS from "../graphql/getDiagnosis.query.gql";
import GET_EMAIL_REDIRECT from "../graphql/getEmailRedirect.query.gql";
import GET_INTERVIEW from "../graphql/getInterview.query.gql";
import GET_INTERVIEW_QUESTIONS from "../graphql/getInterviewQuestions.query.gql";
import GET_INTERVIEW_STATUS from "../graphql/getInterviewStatus.query.gql";
import GET_INTERVIEW_MARKER from "../graphql/getInteviewMarker.query.gql";
import GET_MEDICATION from "../graphql/getMedication.query.gql";
import GET_MEDICATIONS from "../graphql/getMedications.query.gql";
import GET_MILITARY_DISCLOSURE from "../graphql/getMilitaryDisclosure.query.gql";
import GET_OCCUPATION from "../graphql/getOccupation.query.gql";
import GET_OCCUPATIONS from "../graphql/getOccupations.query.gql";
import GET_POLICY_DOWNLOAD from "../graphql/getPolicyDownload.query.gql";
import GET_PRODUCT_QUESTIONS from "../graphql/getProductQuestions.query.gql";
import GET_QUESTIONS from "../graphql/getQuestions.query.gql";
import GET_QUOTE from "../graphql/getQuote.query.gql";
import GET_QUOTE_ESTIMATE_OVERRIDE from "../graphql/getQuoteEstimateOverride.query.gql";
import REMOVE_CREDIT_CARD from "../graphql/removeCreditCard.mutation.gql";
import UPDATE_ACCOUNT from "../graphql/updateAccount.mutation.gql";
import UPDATE_INTERVIEW from "../graphql/updateInterview.mutation.gql";
import UPDATE_QUOTE from "../graphql/updateQuote.mutation.gql";
import { useDecision } from "./desision";
import { useMutation } from "./mutation";
import { useQuery } from "./query";
import { useTraversables } from "./traversables";
// Method naming for queries should start with query* and mutations should start with mutate*

/**
 * Generic resource query method to simplify api calls
 * Returns an object with a payload, success status and message.
 * @param {object} apollo - The apollo object
 * @param {string} prop - The prop from the response data object
 * @param {object} resource - The apollo query
 * @param {object} variables - variables for the query
 * @returns {object}
 */
async function useResource(apollo, prop, resource, variables) {
  const reporter = useReporter();
  const payload = ref(null);
  const success = ref(true);
  const message = ref("");

  const { query } = useQuery(apollo);
  try {
    const response = await query(resource, variables);
    const { data } = response;
    payload.value = data;
  } catch (error) {
    reporter.error("Error", error);
    success.value = false;
    message.value = `Unable to query ${prop}`;
  }

  return {
    payload,
    success: readonly(success),
    message: readonly(message),
  };
}

/**
 * Parent method that returns address query and mutation methods
 * @param {object} apollo
 * @returns {object} address
 */
export function useAddress(apollo) {
  /**
   * Returns an object containing the payload, success, and message properties for getAddress query.
   * @param {string} query
   * * @param {string} preferState
   * @returns {object}
   */
  async function queryAddress(query, preferStates, preferState) {
    const variables = { query, preferStates, preferState };
    return await useResource(apollo, "address", GET_ADDRESS, variables);
  }

  return {
    queryAddress,
  };
}

/**
 * Parent method that returns agency query and mutation methods
 * @param {object} apollo
 * @returns {object} agency
 */
export function useAgency(apollo) {
  /**
   * Returns an object containing the payload, success, and message properties for getAgency query.
   * @param {string} id
   * @returns {object}
   */
  async function queryAgency(id) {
    const variables = { id };
    return await useResource(apollo, "agency", GET_AGENCY, variables);
  }

  return {
    queryAgency,
  };
}

/**
 * Parent method that returns agent query and mutation methods
 * @param {object} apollo
 * @returns {object} agent
 */
export function useAgent(apollo) {
  /**
   * Returns an object containing the payload, success, and message properties for getAgent query.
   * @param {string} id - agents id
   * @returns {object}
   */
  async function queryAgent(id) {
    const variables = { id };
    return await useResource(apollo, "agent", GET_AGENT, variables);
  }

  return {
    queryAgent,
  };
}

/**
 * Parent method that returns agent query and mutation methods
 * @param {object} apollo
 * @returns {object} applicant
 */
export function useApplicant(apollo) {
  const { mutation } = useMutation(apollo);
  /**
   * Returns an object containing the payload, success, and message properties for getAgent query.
   * @param {string} email - applicant's email
   * @param {string} state - applicant's state
   * @param {string} id - agent's id
   * @param {string} params - query params use in email sigin url
   * @returns {object}
   */
  async function mutateApplicant(email, state, id, params = "source=web&imo=true") {
    const variables = { email, state, agentID: id, queryParams: params };
    const { response } = await mutation(CREATE_APPLICANT, variables);
    const { data } = response;
    if (!data.createAccountWithBoundInterview.success)
      return {
        payload: null,
        success: data.createAccountWithBoundInterview.success,
        message: data.createAccountWithBoundInterview.message,
      };
    return {
      payload: {
        account: data.createAccountWithBoundInterview.account,
        interview: data.createAccountWithBoundInterview.interview,
      },
      success: data.createAccountWithBoundInterview.success,
      message: data.createAccountWithBoundInterview.message,
    };
  }

  return {
    mutateApplicant,
  };
}

export function useAccount(apollo) {
  const { mutation } = useMutation(apollo);

  async function queryEmailRedirect(email) {
    const variables = { email };
    return await useResource(apollo, "getEmailRedirect", GET_EMAIL_REDIRECT, variables);
  }

  async function queryAccountVerification(email) {
    const variables = { email };
    return await useResource(apollo, "accountInfo", ACCOUNT_VERIFICATION, variables);
  }

  async function queryAccount(token) {
    const variables = { token };
    return await useResource(apollo, "account", GET_ACCOUNT, variables);
  }

  async function mutateAccount(variables) {
    // const variables = { id, firstName, lastName, email, phoneNumber };
    const { response } = await mutation(UPDATE_ACCOUNT, variables);
    const { data, success, message } = response;

    if (!data) {
      return {
        payload: null,
        success,
        message,
      };
    }

    return {
      payload: data.updateAccount,
      success: true,
      message: null,
    };
  }
  async function createAccount(accountInput) {
    const variables = { accountInput }; // variables { accountInput: accountInput }
    const { response } = await mutation(CREATE_ACCOUNT, variables);
    const { data, success, message } = response;
    if (!data) {
      return {
        payload: null,
        success,
        message,
      };
    }

    return {
      payload: data.createAccount,
      success: true,
      message: null,
    };
  }

  async function createBillingAccount() {
    const { response } = await mutation(CREATE_BILLING_ACCOUNT);
    return response;
  }

  async function queryPolicyDownload(id) {
    const variables = { id };
    return await useResource(apollo, "policyDownloadURL", GET_POLICY_DOWNLOAD, variables);
  }
  return {
    createAccount,
    queryAccount,
    mutateAccount,
    queryAccountVerification,
    createBillingAccount,
    queryEmailRedirect,
    queryPolicyDownload,
  };
}

export function useCreditCards(apollo) {
  const { mutation } = useMutation(apollo);

  async function queryCreditCards() {
    return await useResource(apollo, "creditCards", GET_CREDIT_CARDS);
  }

  async function addCreditCard(token, setDefault) {
    const variables = { token, setDefault };
    const { response } = await mutation(ADD_CREDIT_CARD, variables);
    const { data, success, message } = response;
    if (!data) {
      return {
        payload: null,
        success,
        message,
      };
    }
    return {
      payload: data.attachCreditCard,
      success: true,
      message: "",
    };
  }

  async function deleteCreditCard(id) {
    const variables = { id };
    try {
      const { response } = await mutation(REMOVE_CREDIT_CARD, variables);
      return { success: response.data.detachCreditCard };
    } catch (error) {
      console.log("Error", error);
      return { success: false };
    }
  }

  return {
    addCreditCard,
    deleteCreditCard,
    queryCreditCards,
  };
}

/**
 * Parent method interview query and mutations for agents, agency, ect
 * @param {object} apollo
 * @returns {object}
 */
export function useInterview(apollo) {
  const { mutation } = useMutation(apollo);

  async function addInterviewMeta(id, update) {
    const variables = { id, update };
    const { response } = await mutation(ADD_INTERVIEW_META, variables);
    const { data, success, message } = response;
    if (!data) return { payload: null, success, message };
    return {
      payload: data.addInterviewMarker,
      success: true,
      message: "",
    };
  }

  async function addMarker(id, name, metadata) {
    const variables = { id, name, metadata };
    const { response } = await mutation(ADD_MARKER, variables);
    const { data, success, message } = response;
    if (!data) {
      return {
        payload: null,
        success,
        message,
      };
    }
    return {
      payload: data.addInterviewMarker,
      success: true,
      message: "",
    };
  }

  async function bindAgent(id, update) {
    const variables = { id, update };
    const { response } = await mutation(BIND_AGENT, variables);
    return { payload: response.data, success: response.data.interview };
  }

  async function createInterview(state, bind = true, accountID) {
    const variables = { state, bind, accountID };
    const { response } = await mutation(CREATE_INTERVIEW, variables);
    const { data, success, message } = response;
    if (!data) {
      return {
        payload: null,
        success,
        message,
      };
    }

    return {
      payload: data.createInterview,
      success: true,
      message: "",
    };
  }

  /**
   * Query for agent interviews
   * @param {string} id - agent id
   * @param {string} interviewStatus - excepts value OPEN || CLOSES, Default OPEN:
   * @returns {object}
   */
  async function queryAgentInterviews(id, interviewStatus) {
    const variables = { id, interviewStatus };
    return await useResource(apollo, "agentInterviews", GET_AGENT_INTERVIEWS, variables);
  }

  /**
   * Query for agency interviews
   * @param {string} id - agency id
   * @param {string} interviewStatus - excepts value OPEN || CLOSES, Default OPEN:
   * @returns {object}
   */
  async function queryAgencyInterviews(id, interviewStatus, take, skip) {
    const variables = { agencyId: id, interviewStatus, take, skip };
    return await useResource(apollo, "agencyInterviews", GET_AGENCY_INTERVIEWS, variables);
  }

  /**
   * General query for interviews
   * @param {string} id - agent || agency id
   * @param {string} role = excepts value AGENT || OWNER, Default AGENT
   * @param {string} interviewStatus - excepts value OPEN || CLOSES, Default OPEN:
   * @returns {object}
   */
  async function queryInterviews(id, role = ROLES.AGENT, interviewStatus = STATUS.OPEN, take, skip) {
    if (role === ROLES.AGENT) return await queryAgentInterviews(id, interviewStatus);
    if (role === ROLES.OWNER) return await queryAgencyInterviews(id, interviewStatus, take, skip);
  }

  async function queryInterview(id) {
    const variables = { id };
    return await useResource(apollo, "interview", GET_INTERVIEW, variables);
  }

  async function queryInterviewMarker(id, name) {
    const variables = { id, name };
    return await useResource(apollo, "getInterviewMarkerByName", GET_INTERVIEW_MARKER, variables);
  }

  async function queryInterviewStatus(id) {
    const variables = { id };
    return await useResource(apollo, "interview", GET_INTERVIEW_STATUS, variables);
  }

  async function mutateInterview(id, answers) {
    const variables = { id, answers };
    const { mutation } = useMutation(apollo);
    const { response } = await mutation(UPDATE_INTERVIEW, variables);
    const { data } = response;
    const errors = data.putInterviewAnswers.filter((answer) => !answer.success);

    if (errors.length) return { payload: [], errors, success: false };

    return { payload: data.putInterviewAnswers, success: true, errors: null };
  }

  async function queryQuestions(id) {
    const variables = { id };
    return await useResource(apollo, "interview", GET_QUESTIONS, variables);
  }

  async function queryMilitaryDisclosure(id) {
    const variables = { id };
    return await useResource(apollo, "interview", GET_MILITARY_DISCLOSURE, variables);
  }

  return {
    addMarker,
    addInterviewMeta,
    bindAgent,
    createInterview,
    mutateInterview,
    queryAgentInterviews,
    queryAgencyInterviews,
    queryInterviews,
    queryInterview,
    queryInterviewMarker,
    queryInterviewStatus,
    queryMilitaryDisclosure,
    queryQuestions,
  };
}

export function useOccupation(apollo) {
  async function queryOccupation(keys) {
    const variables = { keys };
    return await useResource(apollo, "occupations", GET_OCCUPATION, variables);
  }

  async function queryOccupations(term) {
    const variables = { term };
    return await useResource(apollo, "occupations", GET_OCCUPATIONS, variables);
  }

  return {
    queryOccupation,
    queryOccupations,
  };
}

export function useQuestions(apollo) {
  async function queryAnswers(id, keys = [], concerns = [], categories = []) {
    const variables = { id, keys, concerns, categories };
    return await useResource(apollo, "questions", GET_ANSWERS, variables);
  }

  async function queryProductQuestions(variant, vendor) {
    if (!variant) variant = "ICC";
    if (!vendor) vendor = "DAYFORWARD";
    const variables = { variant, vendor };
    return await useResource(apollo, "allQuestionsForProductVariant", GET_PRODUCT_QUESTIONS, variables);
  }

  async function queryInterviewQuestions(id, keys = [], concerns = [], categories = []) {
    const variables = { id, keys, concerns, categories };
    return await useResource(apollo, "allQuestionsForProductVariant", GET_INTERVIEW_QUESTIONS, variables);
  }

  async function queryQuestion(slug, formState, direction) {
    const controller = direction === "to";
    const { decideTo, decideFrom } = useDecision();
    const { traverseFrom, traverseTo } = useTraversables();
    const decision = controller ? decideTo(slug, formState) : decideFrom(slug, formState);
    const path = controller ? traverseTo(slug, decision) : traverseFrom(slug, decision);
    const response = QUESTIONS.find((q) => q.slug === path);
    if (!response) return { sucess: !!response, payload: null };
    return { success: !!response, payload: { ...response } };
  }

  return {
    queryAnswers,
    queryQuestion,
    queryProductQuestions,
    queryInterviewQuestions,
  };
}

export function useQuote(apollo) {
  const { mutation } = useMutation(apollo);

  async function queryQuote(id, override) {
    const variables = { interviewID: id, override };
    return await useResource(apollo, "quote", GET_QUOTE, variables);
  }

  async function mutateQuote(id, override) {
    const variables = { interviewID: id, override };
    const { response } = await mutation(UPDATE_QUOTE, variables);
    const { data, success, message } = response;

    if (!data) return { payload: null, success, message };
    return { payload: data.quoteUpdate, success: true, message: "" };
  }

  return {
    queryQuote,
    mutateQuote,
  };
}

export function useQuoteEstimate(apollo) {
  async function queryQuoteEstimateOverride(variables) {
    return await useResource(apollo, "quoteEstimateOverride", GET_QUOTE_ESTIMATE_OVERRIDE, variables);
  }
  return {
    queryQuoteEstimateOverride,
  };
}

export function useDiagnosis(apollo) {
  async function queryDiagnoses(term) {
    const variables = { term };
    return await useResource(apollo, "diagnoses", GET_DIAGNOSES, variables);
  }
  async function queryDiagnosis(codes) {
    const variables = { codes };
    return await useResource(apollo, "diagnoses", GET_DIAGNOSIS, variables);
  }

  return {
    queryDiagnoses,
    queryDiagnosis,
  };
}

export function useMedications(apollo) {
  async function queryMedications(match) {
    const variables = { match };
    return await useResource(apollo, "medications", GET_MEDICATIONS, variables);
  }
  async function queryMedication(names) {
    const variables = { names };
    return await useResource(apollo, "medications", GET_MEDICATION, variables);
  }

  return {
    queryMedication,
    queryMedications,
  };
}

export function useInteractions(apollo) {
  const { mutation } = useMutation(apollo);

  async function setInteraction(id, update) {
    const variables = { id, update };
    const { response } = await mutation(ADD_INTERACTION, variables);

    if (response.data.error) return { success: false, message: response.data.message };
    return { success: true, message: "" };
  }

  return {
    setInteraction,
  };
}

export function useWaitlist(apollo) {
  const { mutation } = useMutation(apollo);

  async function addToWaitlist(entry) {
    const variables = { entry };
    const { response } = await mutation(ADD_TO_WAITINGLIST, variables);
    const { success, message } = response.data.createWaitlistEntry;
    return { success, message };
  }
  return {
    addToWaitlist,
  };
}
