import { DateTime } from "luxon";

import { isObject } from "./object";
import { sentenceCase } from "./sentenceCase";
import { isString, pluralize } from "./string";

export function timeago(value) {
  return DateTime.fromISO(value).toRelativeCalendar();
}

export function pastDate(value) {
  return DateTime.now().minus({ year: value }).toLocaleString();
}

// Parse "MM/YYYY" format as it's not parsable by luxon or new Date as is.
export function parseDate(value) {
  const values = value.split("/");
  return DateTime.fromJSDate(new Date(values[1], values[0]));
}

export function formatDate(value, format = DateTime.DATETIME_MED) {
  if (!value) return value;
  format = format in DateTime ? DateTime[format] : format;
  if (isString(format)) return DateTime.fromISO(value).toFormat(format);
  if (isObject(format)) return DateTime.fromISO(value).toLocaleString(format);
}

export function convertMonths(value) {
  const n = value / 12;
  const y = Math.floor(n);
  const m = Math.floor(12 * (n - y));
  let results = [];

  if (y) results.push(`${y}${pluralize("yr", "yrs", y)}`);
  if (m) results.push(`${m}${pluralize("mo", "mos", m)}`);

  return results.join(" ");
}

// @TODO make compareDates more flexable with date object or date string like 10/11/1985 or 10/2022
export function compareDates(d1, d2) {
  if (!d1 || !d2) return true;
  if (d1.length === 7) {
    d1 = parseDate(d1);
    d2 = parseDate(d2);
  }
  const minDate = new Date(d1);
  const maxDate = new Date(d2);
  minDate.setHours(0, 0, 0, 0);
  maxDate.setHours(0, 0, 0, 0);
  return minDate <= maxDate;
}

// returns "today", "yesterday", or "LLL dd" date formats
export function parseDateShort(date) {
  const string = timeago(date);
  const valid = ["today", "yesterday", "2 days ago", "3 days ago", "4 days ago", "5 days ago", "6 days ago"];

  return valid.includes(string) ? sentenceCase(string) : formatDate(date, "LLL dd");
}
