<template>
  <div :class="$style.user">
    <div :class="$style.account">
      <BaseAvatar :user="userStore.user" v-if="userStore.hasProp('$user')"></BaseAvatar>
      <div :class="$style.meta">
        <span :class="$style.name">{{ name }}</span>
        <span :class="$style.agency" v-if="agency">{{ agency.name ? agency.name : "Agency Placeholder" }}</span>
      </div>
    </div>
    <BaseButton :class="$style.logout" icon aria-label="Logout" @click="signOut">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        enable-background="new 0 0 24 24"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        aria-hidden="true"
        focusable="false"
      >
        <g><path d="M0,0h24v24H0V0z" fill="none" /></g>
        <g>
          <path
            d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z"
          />
        </g>
      </svg>
    </BaseButton>
  </div>
</template>

<script setup>
import BaseAvatar from "@dayforward/common/src/components/BaseAvatar.vue";
import BaseButton from "@dayforward/common/src/components/BaseButton.vue";
import { sliceEnds } from "@dayforward/common/src/utils";
import { computed } from "vue-demi";
import { useRouter } from "vue-router";

import { routeNames } from "../constants";
import { useAuthStore } from "../store/auth.store";
import { useUserStore } from "../store/user.store";

const authStore = useAuthStore();
const router = useRouter();
const userStore = useUserStore();

const agency = computed(() => {
  return userStore.user.agency;
});

const name = computed(() => {
  if (!userStore.hasProp("$user")) return "";
  return userStore.user?.name.length ? userStore.user?.name : sliceEnds(userStore.user?.agentEmail);
});

const signOut = async () => {
  await authStore.signOut();
  router.replace({ name: routeNames.IMO_SIGNIN });
};
</script>

<style lang="scss" module>
.account,
.user {
  display: flex;
}

.account {
  margin-right: var(--space-xs);
}
.user {
  align-items: center;
}

.name,
.agency {
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.name {
  display: block;
  font-weight: 600;
}

.agency {
  display: block;
  font-size: var(--base-text-sm);
}

.meta {
  max-width: 200px;
  margin-left: var(--space-xs);
  overflow: hidden;
}

.logout {
  margin-right: -12px;
}
</style>
