<template>
  <div>
    <AppHeader :to="{ name: routeTo }">
      <AppUserNav v-if="!invalidNames.includes(route.name)" />
    </AppHeader>
    <BaseMain>
      <BaseContainer app>
        <BaseContent>
          <router-view />
        </BaseContent>
      </BaseContainer>
    </BaseMain>
  </div>
</template>

<script setup>
import BaseContainer from "@dayforward/common/src/components/BaseContainer.vue";
import BaseContent from "@dayforward/common/src/components/BaseContent.vue";
import BaseMain from "@dayforward/common/src/components/BaseMain.vue";
import { computed, onBeforeMount } from "vue";
import { useRoute } from "vue-router";

import AppHeader from "../components/AppHeader.vue";
import AppUserNav from "../components/AppUserNavigation.vue";
import { routeNames } from "../constants";
import { useApollo } from "../plugins/apollo";
import { useUserStore } from "../store/user.store";
const route = useRoute();
const userStore = useUserStore();
const { hasProp, isAgent, fetchAgency } = userStore;
const apollo = useApollo();
const invalidNames = [routeNames.IMO_SIGNIN, routeNames.IMO_CONSENT, routeNames.IMO_CONSENT_CONFIRMED];

let routeTo = computed(() => {
  if (route.name === routeNames.IMO_CONSENT) return routeNames.IMO_CONSENT;
  if (route.name === routeNames.IMO_CONSENT_CONFIRMED) return routeNames.HOME;
  return routeNames.IMO_DASHBOARD_APPLICATIONS;
});

onBeforeMount(async () => {
  if (hasProp("$user") && isAgent) {
    await fetchAgency(apollo, userStore.user?.agencyId);
  }
});
</script>

<style lang="scss" module>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}
</style>
