import { excludeConcerns, healthConditions, questionKeys } from "../constants";
//TODO: move all this to interview.utils
import { accountQuery, interviewAnswersQuery } from "../pages/interview/interview.queries";

// @TODO DEPRICIATED Remove after refactor complete still currently used in interview components
export const returnAnswerErrors = (res) => {
  let errors = [];
  for (let i = 0; i < res.length; i++) {
    const obj = res[i];
    if (!obj.success) {
      errors = [...errors, ...[{ questionKey: obj.questionKey, message: obj.message }]];
    }
  }
  return {
    errors,
  };
};

export const returnHealthConditionsAnswers = (res) => {
  const active = reflexivesActivatedForKey(res, questionKeys.HEALTH_CONDITIONS);
  return active.length ? active : [""];
};

export const reflexivesActivatedForKey = (res, key) => {
  const object = res.find((item) => item.questionKey === key);
  if (!object) return [];
  return object.reflexives.reduce((accumulator, reflexive) => {
    if (reflexive.targetKey !== key && reflexive.isSatisfied) {
      const conditions = reflexive.conditions[0];
      if (conditions && conditions?.value !== healthConditions.SUBSTANCE_ABUSE) accumulator.push(conditions?.value);
      // @TODO Break out of reducer early here
      if (
        conditions?.value === healthConditions.SUBSTANCE_ABUSE &&
        !accumulator.includes(healthConditions.SUBSTANCE_ABUSE)
      ) {
        accumulator.push(conditions?.value);
      }
    }
    return accumulator;
  }, []);
};

export const returnAnswerValuesForKey = (res, key) => {
  if (res) {
    for (let i = 0; i < res.length; i++) {
      const obj = res[i];
      if (obj?.questionKey === key) {
        return obj?.values;
      }
    }
  }
  return null;
};

export const questionForConcern = (concern, questions, exclude = []) => {
  let question;
  for (const q of questions) {
    const intersection = exclude.filter((item) => {
      return q.concerns.includes(item);
    });
    if (q.concerns.includes(concern) && !intersection.length) {
      question = q;
    }
  }
  return question;
};

export const filterQuestionsForConcern = (concern, questions, exclude = []) => {
  let questionArray = [];
  for (const q of questions) {
    const intersection = exclude.filter((item) => {
      return q.concerns.includes(item);
    });
    if (q.concerns.includes(concern) && !intersection.length) {
      questionArray.push(q);
    }
  }
  return questionArray;
};

export const questionsForConcerns = (concerns, questions) => {
  const concernsQuestionsMap = {};
  const concernsQuestionKeysMap = {};
  for (let [key, value] of Object.entries(concerns)) {
    const exclude = excludeConcerns[key];
    const question = filterQuestionsForConcern(value, questions, exclude);
    for (const q of question) {
      if (q) {
        concernsQuestionsMap[key] = q;
        concernsQuestionKeysMap[key] = q.key;
      }
    }
  }
  return {
    concernsQuestionsMap,
    concernsQuestionKeysMap,
  };
};

// @TODO DEPRICIATED Remove after refactor complete
export const returnCategories = (step) => {
  if (step && Array.isArray(step.categories)) {
    return step.categories;
  } else {
    return [];
  }
};
// @TODO DEPRICIATED Remove after refactor complete
export const returnConcerns = (step) => {
  if (step && Array.isArray(step.concerns)) {
    return step.concerns;
  } else {
    return [];
  }
};

// @TODO DEPRICIATED Remove after refactor complete
// @TODO move to graphql folder
export const getInterviewQuestions = async (apollo, keys = [], id, concerns = [], categories = []) => {
  const res = await apollo.query({
    query: interviewAnswersQuery,
    fetchPolicy: "no-cache",
    variables: {
      id: id,
      keys: keys,
      concerns: concerns,
      categories: categories,
    },
  });
  return res;
};

// @TODO DEPRICIATED Remove after refactor complete
export const getAccount = async (apollo, token) => {
  const res = await apollo.query({
    query: accountQuery,
    fetchPolicy: "no-cache",
    variables: {
      token: token,
    },
  });
  return res;
};

// @TODO DEPRICIATED Remove after refactor complete'
export const hasErrorKey = (key, errorsArr) => {
  return errorsArr && errorsArr.indexOf(key) !== -1;
};
// @TODO DEPRICIATED Remove after refactor complete
// convert an array of strings to sentence case.
export const convertReadable = (vals) => {
  let newArr = [];
  vals.forEach((item) => {
    const str = item.replace(/_/g, " ").toLowerCase();
    const upperCase = str[0].toUpperCase() + str.slice(1);
    newArr.push(upperCase);
  });
  return newArr.toString().replace(/,/g, ", ");
};

// Returns if user has accepted notices
export function acceptedNotice(/** Object */ interview) {
  return interview.questions
    .find((question) => question.key === questionKeys.ACCEPTED_NOTICES)
    .answers.some((value) => value === "true");
}

export function imoAccount(/** Object */ interview) {
  return interview.agentId.length;
}
