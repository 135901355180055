/**
 * Auth store should handle signin, logout, auth sessions, ect.
 */
import { getAuth, signOut } from "firebase/auth";
import { DateTime } from "luxon";
import { defineStore } from "pinia";

import { STORAGE_KEYS, useLocalStorage, useSessionStorage } from "../composables/storage";
import { useEstimateStore } from "./estimate.store";
import { useInterviewStore } from "./interview.store";
import { useUserDataStore } from "./modules/user-data.module";
import { useUserStore } from "./user.store";

const { get: getSession, remove: removeSession } = useSessionStorage();
const sessionId = getSession(STORAGE_KEYS.DF_SESSION_ID);

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      $id: sessionId ? sessionId.id : Math.random().toString(36).substring(2),
      $mode: null,
      $email: null,
      $authenticated: false,
      $session: null,
    };
  },
  getters: {
    id: (state) => {
      return state.$id;
    },
    user: () => {
      const auth = getAuth();
      return auth.currentUser;
    },
    authenticated: (state) => {
      return state.$authenticated;
    },
    session: (state) => {
      return state.$session;
    },
    email: (state) => {
      return state.$email;
    },
  },
  actions: {
    setState(prop, data) {
      this[prop] = data;
    },
    setSession() {
      const { set } = useLocalStorage();
      const date = DateTime.utc();
      this.setState("$session", date.ts);
      set(STORAGE_KEYS.DF_SESSION, this.$session);
      console.log("Session stored");
    },
    /**
     * Evaluates current session to see if it's within session limits
     * Firebase one hour auth limit and max of two hours for user session
     * @returns { redirect:boolean, refresh:boolean }
     */
    currentSession() {
      const { get } = useLocalStorage();
      const session = get(STORAGE_KEYS.DF_SESSION);
      const defaultResponse = { redirect: false, refresh: false };
      if (session) {
        const now = DateTime.now();
        const sessionTime = DateTime.fromMillis(parseInt(session));
        const expirationTime = DateTime.fromMillis(this.user.stsTokenManager.expirationTime);
        const { hours: sessionLimit } = now.diff(sessionTime, "hours").toObject(); // Increases in time
        const { hours: authLimit } = expirationTime.diff(now, "hours").toObject(); // Decreases in time
        // Check session limits
        if (sessionLimit < 2) {
          if (authLimit <= 0) return { ...defaultResponse, refresh: true };
          return defaultResponse;
        }
      }
      // If no current session or session has exceeded seesion limits
      return { ...defaultResponse, redirect: true };
    },
    async sessionEffect() {
      const response = this.currentSession(); // returns { redirect:Boolean, refresh:Boolean }
      if (response.refresh) await getAuth().currentUser.getIdToken(true);
      if (response.redirect) this.signOut();
      return response;
    },
    async signOut() {
      const { remove } = useLocalStorage();

      const auth = getAuth();
      const interviewStore = useInterviewStore();
      const userDataStore = useUserDataStore(); // @DEPRECIATED
      const userStore = useUserStore();
      const estimateStore = useEstimateStore();
      userDataStore.$reset(); // @TODO Convert data to auth.store or user.store and remove
      interviewStore.$reset();
      userStore.$reset();
      estimateStore.$reset();

      this.$reset();
      this.setState("$mode", null);
      remove(STORAGE_KEYS.DF_USER);
      remove(STORAGE_KEYS.DF_EMAIL);
      remove(STORAGE_KEYS.DF_SESSION);

      removeSession(STORAGE_KEYS.DF_LEAD);
      removeSession(STORAGE_KEYS.DF_ESTIMATE);
      await signOut(auth);
    },
  },
});
