<template>
  <nav :class="$style.nav" aria-label="Main" @click="handleClick">
    <BaseContainer :class="$style.container">
      <RouterLink
        data-analytics="why_dayforward"
        role="menuitem"
        :class="[$style.link, $style['link--main']]"
        :to="{ name: routeNames.WHY_DAYFORWARD }"
      >
        Why Dayforward
      </RouterLink>
      <RouterLink
        data-analytics="about"
        role="menuitem"
        :class="[$style.link, $style['link--main']]"
        :to="{ name: routeNames.ABOUT }"
      >
        About
      </RouterLink>
      <RouterLink
        data-analytics="faqs"
        role="menuitem"
        :class="[$style.link, $style['link--main']]"
        :to="{ name: routeNames.FAQ }"
      >
        FAQs
      </RouterLink>

      <RouterLink
        data-analytics="get_started"
        role="menuitem"
        :class="[$style.link, $style['link--secondary']]"
        :to="{ name: routeNames.Q_SLUG, params: { slug: SLUGS.dob }}"
      >
        Get started
      </RouterLink>
      <RouterLink
        data-analytics="signin"
        role="menuitem"
        :class="[$style.link, $style['link--secondary']]"
        :to="{ name: routeNames.AUTH_SIGN_IN }"
        v-if="!loggedIn"
      >
        Sign in
      </RouterLink>
      <RouterLink
        data-analytics="signout"
        role="menuitem"
        :class="[$style.link, $style['link--secondary']]"
        :to="{ name: routeNames.AUTH_SIGN_OUT }"
        v-else
      >
        Log out
      </RouterLink>
    </BaseContainer>
  </nav>
</template>

<script setup>
import { useAnalytics } from "@common";
import { useExperiment } from "@composables";
import { computed, inject } from "vue";
import { useRoute, useRouter } from "vue-router";

import { routeNames, SLUGS } from "../constants";
import { useAuthStore } from "../store/auth.store";
import BaseContainer from "./BaseContainer.vue";

const api = inject("api");
const authStore = useAuthStore();

const loggedIn = computed(() => authStore.authenticated);

// Analytics
const analytics = useAnalytics();
const route = useRoute();
const router = useRouter();
const { bucket } = useExperiment();
function trackEvent(e) {
  // Track main navigation clicks
  const target = e.target.closest("a") || e.target.closest("button");
  if (!target) return;
  const event = target.getAttribute("data-analytics");
  const current = router.options.history.state.current;
  const page = current === "/" ? "homepage" : current.substring(1);

  if (event) {
    const key = `app_click_${event}`;
    analytics.trackEvent(key, {
      button_location: "main_navigation",
      page,
      region: "main_navigation",
      url: route.path,
      experiment: bucket.value,
    });
  }
}
// END

function handleClick(e) {
  // Analytics
  trackEvent(e);
  // Close main navigation
  api.close();
}
</script>

<style lang="scss" module>
.nav {
  position: fixed;
  top: 64px;
  right: 0;
  width: 100%;
  height: calc(100% - 64px);
  padding-top: 5rem;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: var(--sugar);
  }
}

.container {
  position: relative;
  z-index: 1;
  display: grid;
  align-content: flex-start;
}

.link {
  justify-self: flex-start;
  position: relative;
  color: var(--geranium);
  text-decoration: none;
  outline: none;
  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 1px;
    background: currentColor;
    transition: width 300ms ease-in-out, left 300ms ease-in-out;
  }
  &:hover:after,
  &:focus-visible:after {
    width: 100%;
  }
}

.link--main {
  margin-bottom: var(--space-default);
  font-weight: 600;
  font-size: 2rem;
  &:nth-child(3) {
    margin-bottom: var(--space-xl);
  }
}

.link--secondary {
  margin-bottom: var(--space-sm);
  font-size: var(--base-text-xl);
}

@media (min-width: 764px) {
  .nav {
    padding-top: 8rem;
    text-align: right;
    &:after {
      width: 50%;
      min-width: 600px;
    }
  }

  .container {
    justify-content: flex-end;
  }

  .link {
    justify-self: flex-end;
    &:after {
      left: unset;
      right: 0;
    }
  }

  .link--main {
    font-size: 3rem;
    &:nth-child(3) {
      margin-bottom: var(--space-xxl);
    }
  }
}

@media (min-width: 992px) {
  // .nav {

  // }
}
</style>
