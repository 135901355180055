//import { useAuthStore } from "../../store/modules/auth.module";
import { PATHS, routeNames } from "../constants";
import AgentLayout from "../layouts/AgentLayout.vue";
import { useFlagsmithStore } from "../store/flagsmith.store";

export const imoRoutes = [
  {
    path: PATHS.IMO,
    redirect: { name: routeNames.IMO_SIGNIN },
    meta: {
      doNotTrack: true,
      requiresAuth: true,
      requiresAgent: true,
      layout: AgentLayout,
    },
    component: () => import("../pages/agent/AgentModual.vue"),
    async beforeEnter(to) {
      // Route guard moved to router/index.js route.beforeEnter
      const flagsmithStore = useFlagsmithStore();
      if (
        !flagsmithStore.features?.hasFeature("imo_application") &&
        !to.path.includes("dashboard") &&
        to.name !== routeNames.IMO &&
        to.name !== routeNames.IMO_SIGNIN &&
        flagsmithStore.features.flagsmithReady
      ) {
        return { name: routeNames.IMO_DASHBOARD_APPLICATIONS };
      }
    },
    children: [
      {
        path: PATHS.IMO_SIGNIN,
        name: routeNames.IMO_SIGNIN,
        component: () => import("../pages/agent/AgentSignIn.vue"),
      },
      {
        path: PATHS.IMO_APPLICANT_ESTIMATE,
        name: routeNames.IMO_APPLICANT_ESTIMATE,
        component: () => import("../pages/agent/AgentQuoteEstimate.vue"),
      },
      {
        path: PATHS.IMO_DASHBOARD,
        name: routeNames.IMO_DASHBOARD,
        component: () => import("../pages/agent/AgentDashboard.vue"),
        children: [
          {
            path: PATHS.DEFAULT,
            name: routeNames.IMO_DASHBOARD_APPLICATIONS,
            component: () => import("../pages/agent/AgentDashboardApplications.vue"),
            meta: {
              disableScroll: true,
            },
            children: [
              {
                path: PATHS.PARAM_ID,
                name: routeNames.IMO_DASHBOARD_APPLICATIONS_APPLICANT,
                component: () => import("../pages/agent/AgentDashboardApplicant.vue"),
                props: true,
                meta: {
                  disableScroll: true,
                },
              },
            ],
          },
          {
            path: PATHS.IMO_DASHBOARD_POLICIES,
            name: routeNames.IMO_DASHBOARD_POLICIES,
            component: () => import("../pages/agent/AgentDashboardPolicies.vue"),
            children: [
              {
                path: PATHS.PARAM_ID,
                name: routeNames.IMO_DASHBOARD_POLICIES_APPLICANT,
                component: () => import("../pages/agent/AgentDashboardApplicant.vue"),
                props: true,
                meta: {
                  disableScroll: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: PATHS.IMO_CONSENT,
        redirect: { name: routeNames.IMO_CONSENT },
        component: () => import("../pages/agent/ApplicantConsentIndex.vue"),
        props: (route) => ({ id: route.query.interviewId }),
        meta: {
          doNotTrack: true,
          initialConsent: false,
          requiresAuth: true,
          requiresAgent: false,
        },
        children: [
          {
            path: PATHS.DEFAULT,
            name: routeNames.IMO_CONSENT,
            component: () => import("../pages/agent/ApplicantConsent.vue"),
          },
          {
            path: PATHS.IMO_CONSENT_CONFIRMED,
            name: routeNames.IMO_CONSENT_CONFIRMED,
            component: () => import("../pages/agent/ApplicantConsentConfirmed.vue"),
            beforeEnter(to, from) {
              if (from.meta.initialConsent) to.meta.initialConsent = true;
            },
          },
        ],
      },
      {
        path: PATHS.IMO_APPLICANT_CREATE,
        name: routeNames.IMO_APPLICANT_CREATE,
        component: () => import("../pages/agent/AgentApplicantCreate.vue"),
      },
      {
        path: PATHS.IMO_APPLICANT_DETAIL,
        name: routeNames.IMO_APPLICANT_INDEX,
        component: () => import("../pages/agent/AgentApplicantIndex.vue"),
        props: true,
        children: [
          {
            path: PATHS.DEFAULT,
            name: routeNames.IMO_APPLICANT_DETAIL,
            component: () => import("../pages/agent/AgentApplicantDetail.vue"),
          },
          {
            path: PATHS.IMO_APPLICANT_APPLICATION,
            name: routeNames.IMO_APPLICANT_APPLICATION,
            component: () => import("../pages/agent/AgentApplicantApplication.vue"),
            props: true,
          },
          {
            path: PATHS.IMO_APPLICANT_QUOTE,
            name: routeNames.IMO_APPLICANT_QUOTE,
            meta: {
              title: "Applicant Quote",
            },
            component: () => import("../pages/agent/AgentApplicantQuote.vue"),
          },
        ],
      },
    ],
  },
];
