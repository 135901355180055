<template>
  <a :class="$style.skip" href="#main">Skip to main content</a>
  <header ref="headerRef" :class="[$style.header]">
    <div :class="$style.banner" v-if="$route.name === 'home'">
      <BaseText>
        Transform your insurance business. Learn about our solutions at
        <a href="//dayforward.io" :class="$style['banner__link']">dayforward.io</a>
      </BaseText>
    </div>
    <BaseContainer :class="$style.container">
      <div :class="[$style['group__left']]">
        <router-link
          :class="$style.logo"
          :to="props.to"
          data-analytics="logo"
          aria-label="dayforward.com"
          active-class=""
          exact-active-class=""
          @click="handleClick"
        >
          <slot name="logo">
            <component :is="Logo" />
          </slot>
        </router-link>
      </div>
      <div :class="[$style['group__right']]">
        <slot>
          <a
            :class="$style.phone"
            data-analytics="phone"
            :href="`tel:${phone}`"
            @click="trackEvent"
            v-if="displayPhone"
          >
            +{{ phone }}
          </a>
          <button
            id="toggle"
            type="button"
            aria-label="Main"
            aria-haspopup="true"
            aria-controls="nav"
            :aria-expanded="toggled"
            :class="[$style.toggle, { [$style.active]: toggled }]"
            @click="toggle"
            v-if="showToggle"
          >
            <!-- eslint-disable-next-line prettier/prettier -->
            <svg
              :class="[$style.svg]"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              focusable="false"
              aria-hidden="true"
            >
              <!-- eslint-disable-next-line prettier/prettier -->
              <rect
                :class="[$style.one, { [$style.animate]: toggled }]"
                x="4.5"
                y="6"
                width="16"
                height="1.6"
                fill="currentColor"
              />
              <!-- eslint-disable-next-line prettier/prettier -->
              <rect
                :class="[$style.two, { [$style.animate]: toggled }]"
                x="4.5"
                y="16"
                width="16"
                height="1.6"
                fill="currentColor"
              />
              <!-- eslint-disable-next-line prettier/prettier -->
              <rect
                :class="[$style.three, , { [$style.animate]: toggled }]"
                x="4.5"
                y="11"
                width="16"
                height="1.6"
                fill="currentColor"
              />
            </svg>
          </button>
        </slot>
      </div>
    </BaseContainer>
    <Transition name="drawer">
      <TheNav id="nav" aria-labelledby="toggle" :aria-hidden="!toggled" v-show="toggled" />
    </Transition>
  </header>
</template>

<script setup>
import { KEYS, useAnalytics } from "@common";
import BaseLogo from "@common/components/BaseLogo.vue";
import BaseText from "@common/components/BaseText.vue";
import { isMobile } from "@common/utils";
import BaseContainer from "@components/BaseContainer.vue";
import TheNav from "@components/TheNav.vue";
import { useContent, useExperiment, useForm, useInterviewStep, useKeydown } from "@composables";
import { computed, defineAsyncComponent, onMounted, provide, ref, shallowRef, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";

import { routeNames } from "../constants";
import { useUserStore } from "../store/user.store";

const props = defineProps({
  to: {
    type: Object,
    default: () => ({ name: routeNames.HOME }),
  },
  hideToggle: {
    type: Boolean,
    default: false,
  },
});

// Analytics
const analytics = useAnalytics();
const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const { setCurrentStep } = useInterviewStep();
const { bucket } = useExperiment();
function trackEvent(e) {
  // Track main navigation clicks
  const target = e.target.closest("a") || e.target.closest("button");
  if (!target) return;
  const event = target.getAttribute("data-analytics");
  const current = router.options.history.state.current;
  const page = current === "/" ? "homepage" : current.substring(1);
  if (event) {
    const key = `app_click_${event}`;
    analytics.trackEvent(key, {
      platform: isMobile ? "mobile" : "desktop",
      button_location: "header",
      page,
      region: "header",
      url: route.path,
      experiment: bucket.value,
    });
  }
}

const toggled = ref(false);
const headerRef = ref(null);

const showToggle = computed(() => {
  if (props.hideToggle) return false;
  return !route.path.includes("/q/") && !route.path.includes("interview") && !route.path.includes("/a/");
});

const displayPhone = computed(() => !route.path.includes("/a/"));
const visibleRoutes = [routeNames.INTERVIEW_PAYMENT, routeNames.INTERVIEW_E_SIGN, routeNames.INTERVIEW_THANKS];
// Used to display alt logo for CTL wordmark or base Dayforward wordmark
const Logo = shallowRef(BaseLogo);
watchEffect(
  () => {
    if (userStore.interview) {
      const { product } = userStore.interview;
      if (product.vendor === "CTL" && visibleRoutes.includes(route.name))
        Logo.value = defineAsyncComponent(() => import("./AppLogoAlt.vue"));
    }
  },
  {
    flush: "post",
  },
);

useKeydown({ key: KEYS.Escape, callback: () => close() });

function toggle() {
  toggled.value = !toggled.value;
}

function close() {
  toggled.value = false;
}

function handleClick(e) {
  trackEvent(e);
  setCurrentStep(0);
  close();
}

provide("api", {
  close,
  toggle,
  toggled,
});

watchEffect(() => {
  if (toggled.value) document.body.classList.add("locked");
  if (!toggled.value) document.body.classList.remove("locked");
});

onMounted(() => {
  //window.addEventListener("scroll", handleScroll);
});

const { phone, updatePhone } = useContent();
const { formData } = useForm();
const { hasProp } = useUserStore();
watchEffect(
  () => {
    if ("location" in formData.value) updatePhone(formData.value.location.answers.HOME_STATE);
    if (hasProp("$user")) updatePhone(userStore.user?.address?.state, userStore.interview);
  },
  { flush: "post" },
);
</script>

<style lang="scss" module>
.skip {
  position: absolute;
  left: 50%;
  z-index: 9999;
  padding: 4px;
  color: var(--oat);
  font-size: 14px;
  background: var(--midnight);
  transform: translate(-50%, -100%);
  transition: transform 230ms;
  &:focus {
    transform: translate(-50%, 0);
  }
}

.banner {
  padding: 8px 10%;
  background: var(--geranium);
  color: var(--oat);
  text-align: center;
}

.banner__link {
  color: var(--oat);
  text-decoration: none;
  border-bottom: 1px solid currentColor;
}

@media (max-width: 330px) {
  .banner {
    font-size: 14px;
  }
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  // display: flex;
  width: 100%;
  background: var(--sugar);
  transition: box-shadow 0.25s ease-in 0.1s;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
}

.group__left,
.group__right {
  display: flex;
  align-items: center;
}

.logo {
  display: inline-flex;
  outline: none;
  &:hover {
    border-bottom-color: transparent;
  }
  &:focus-visible {
    box-shadow: 0 0 0 4px rgb(26, 17, 60, 0.2);
  }
}

.phone,
.toggle {
  color: var(--geranium);
}

.phone {
  position: relative;
  font-weight: 600;
  font-size: var(--base-text-xs);
  text-decoration: none;
  outline: none;
  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 0;
    height: 1px;
    background: currentColor;
    transition: width 300ms ease-in-out, left 300ms ease-in-out;
  }
  &:hover:after,
  &:focus-visible:after {
    width: 100%;
    left: 0;
  }
}

.toggle {
  position: relative;
  z-index: 9;
  background: transparent;
  border: 0;
  padding: 0;
  width: 42px;
  height: 42px;
  margin-left: var(--space-xs);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 230ms ease-in-out;
  outline: none;
  cursor: pointer;
  &:hover,
  &:focus-visible {
    background: rgba(var(--base-color-secondary-rgb), 0.06);
  }
}

.one,
.two,
.three {
  transition: all 230ms ease-in-out;
}

.three.animate {
  width: 0;
}
.one.animate {
  transform: rotate(45deg) translate(5.75px, -7px);
  width: 14px;
}

.two.animate {
  transform: rotate(-45deg) translate(-11.5px, 0.5px);
  width: 14px;
}

@media (min-width: 524px) {
  .phone {
    font-size: var(--base-text-sm);
  }

  .toggle {
    margin-left: var(--space-default);
  }
}
</style>

<style>
.drawer-enter-from,
.drawer-leave-to {
  transform: translate(150%, 0);
  transition: all 500ms cubic-bezier(0.4, 0, 1, 1) 0s;
}

.drawer-enter-to,
.drawer-leave-from {
  transform: translate(0, 0);
  transition: all 500ms cubic-bezier(0, 0, 0.2, 1) 0s;
}

.locked {
  overflow: hidden;
}
</style>
